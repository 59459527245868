import * as React from 'react'
import { graphql, navigate } from 'gatsby'
import get from 'lodash/get'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import Grid from '@material-ui/core/Grid'
import Layout from '../components/layout'
import Seo from '../components/seo'
import FeatureDetails from '../components/featuresdetails'
import tradinglaptop1 from '../assets/images/laptop_screenshot_new.png'
import mobilescreenshot from '../assets/images/mobilescreenshot_dual.png'
import awscloudlogo from '../assets/images/awslogo_branded.png'

const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'

class PlatformPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      viewportWidth: isClient ? window.innerWidth : 0,
    }
  }
  changeCookie(cookieName, newState) {
    const { cookies } = this.props
    cookies.set(cookieName, newState, { path: '/' })
    this.setState({ cookieName: newState })
  }
  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth })
  }
  componentDidMount() {
    this.changeCookie('lastpage', '/offers')
    if (!this.props.allCookies.firstload) {            
      setTimeout(() => {
        navigate('/loading')
      }, 500)
    }
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
  }
  componentWillUnmount() {
    if (isClient)
      window.removeEventListener('resize', this.updateWindowDimensions)
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')
    const pageName = 'Platform'

    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }

    var imagewith = 5
    var textwidth = 7
    if (isMobile) {
      imagewith = 12
      textwidth = 12
    }

    return (
      <React.Fragment>
        <Seo pagename={pageName + ' ' + siteTitle} keywords={keywords} />
        <Layout data={this.props} pagewidth={pagewidth.matches}>
          <div className="page_wrapper generalpage_wrapper">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h1 className="generalpage_majorheading">{pageName}</h1>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <p>
                    EdgeForecaster is a multifaceted trading tool, delivering
                    subscribers with the superior data and statistical analysis
                    utilised by the world’s leading quantitative hedge funds.
                    Driven by state-of-the-art artificial intelligence and
                    machine learning, EdgeForecaster supplies users with
                    accurate 5-day and 25-day price forecasts for thousands of
                    global securities. Delivering consistent market edge,
                    EdgeForecaster combines these price forecasts with data from
                    sophisticated quantitative algorithms and visualisations
                    designed to illustrate market behaviour.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={imagewith}>
                    <div className="generalpageimg_cont">
                      <img
                        className="generalpageimg"
                        src={tradinglaptop1}
                        alt="intro"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={textwidth}>
                    <div className="platformtext">
                      <h3 style={{ lineHeight: '1vmin' }}>Web Application</h3>
                      <p>
                        Accessible through any browser, the EdgeForecaster web
                        app provides users with fast and easy access to the data
                        they need to gain market edge. Through the simple,
                        interactive user interface, data is presented in an
                        intuitive way so traders can get an immediate snapshot
                        of what a security is doing. Search thousands of global
                        securities across multiple markets to find your
                        favourites and the ones which match your strategy.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={imagewith}>
                    <div className="generalpageimg_cont">
                      <img
                        className="generalpageimg"
                        src={mobilescreenshot}
                        alt="intro"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={textwidth}>
                    <div className="platformtext">
                      <h3 style={{ lineHeight: '1vmin' }}>Mobile App</h3>
                      <p>
                        Available from the App Store and Google Play, the
                        EdgeForecaster mobile app ensures traders can keep up to
                        date with all the high-end market data they need to make
                        informed, profitable choices wherever they have a mobile
                        phone. Setting tailored notifications for any datapoint
                        on any of your securities is one of the most powerful
                        features available through the EdgeForecaster app.
                        Instantly know if a price is reached, forecast changes
                        or indicator hits a significant point.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={imagewith}>
                    <div className="generalpageimg_cont">
                      <img
                        className="generalpageimg"
                        src={awscloudlogo}
                        alt="intro"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={textwidth}>
                    <div className="platformtext">
                      <h3 style={{ lineHeight: '1vmin' }}>
                        EdgeForecaster Cloud
                      </h3>
                      <p>
                        The EdgeForecaster Cloud is the backbone which brings
                        the whole system together. Hosted on the Amazon Web
                        Services platform, the custom cloud infrastructure
                        design is optimised to process the market data which
                        feeds EdgeForecaster’s quantitative and predictive
                        algorithms. The EdgeForecaster cloud ensures
                        availability worldwide of our accurate price forecasts
                        and bespoke quantitative algorithms, in a fraction of a
                        second.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <FeatureDetails />
            </Grid>
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default withCookies(PlatformPage)
export const PlatformPageQuery = graphql`
  query PlatformPageQuery {
    site {
      siteMetadata {
        title
        description
        menuLinks {
          name
          link
          cl
        }
        keywords
      }
    }
    sitePage {
      pluginCreator {
        name
      }
    }
  }
`
