import React from 'react'
import Grid from '@material-ui/core/Grid';
import priceforecasts from '../assets/images/priceforecasts.png'
import predictiveanalytics from '../assets/images/predictiveanalytics.png'
import quantitativealgos from '../assets/images/quantitativealgos.png'
import visualmarketdepth from '../assets/images/visualmarketdepth.png'
import documentedaccuracy from '../assets/images/documentedaccuracy.png'
import intravisuals from '../assets/images/intravisuals.png'



class FeatureDetails extends React.Component {    
  render() {

    const featuresbutton = 
    <Grid container spacing={0}>
        <Grid item xs={3}/>
        <Grid item xs={6} style={{justifyContent:"center"}}>
            <a href="/plans" className="button_main">SignUp Today</a>
        </Grid>
        <Grid item xs={3}/>
    </Grid>;
    const featuresbuttonMob = 
    <Grid container spacing={0}>
        <Grid item xs={12} style={{justifyContent:"center"}}>
            <a href="/plans" className="button_main">SignUp Today</a>
        </Grid>        
    </Grid>;

      return (
        <div className="pagesection_main" >
          <section id="features" className="wrapper style1 special fade-up" >
            <div className="container" >
                {this.props.format ==="index" ?                     
                    <header className="major">
                        <h2>EdgeForecaster Market Forecasts</h2>
                        <p >EdgeForecaster generates trading signals through quantitative statistical and artificial intelligence-based models, providing tools and indicators for traders who want persistent market edge. EdgeForecaster provides traders with the insight, confidence, and ability to lock in their results.</p>                    
                    </header>
                    :
                    <hr />
                    }
                <div className="box alt">
                    <div className="row gtr-uniform">
                        <section className="col-4 col-6-medium col-12-xsmall">
                            <div className="featureimg_cont">
                                <img className="featureimg" src={priceforecasts} alt="intro" />
                            </div>
                            <h3>Price Forecasts</h3>
                            <p>Having a forecast of the long-and short-term price direction brings definite edge to your trading decisions. Knowing that statistics, probability and historical pattern recognition are in your favour breeds confidence and maximises returns.</p>
                        </section>
                        <section className="col-4 col-6-medium col-12-xsmall">
                            <div className="featureimg_cont">
                                <img className="featureimg" src={predictiveanalytics} alt="intro" />
                            </div>
                            <h3>Quantitative Analytics</h3>
                            <p>Predictive analytics is more than just “mirroring the past” and utilising a few indicators. Encompassing a variety of statistical techniques that analyse current and historical patterns, the EdgeForecaster predictive analytics have been optimised to improve our forecasts, provide insight into market phase and illustrate how best to allocate your resources to achieve optimal results.</p>
                        </section>
                        <section className="col-4 col-6-medium col-12-xsmall">
                            <div className="featureimg_cont">
                                <img className="featureimg" src={quantitativealgos} alt="intro" />
                            </div>
                            <h3>Bespoke Algorithms</h3>
                            <p>Good data produces good results. EdgeForecaster have invested significantly in developing a fast and accurate data pipeline, utilising global best-practices, to ensure users receive to the minute data and the most up-to-date forecasts.</p>
                        </section>
                        <section className="col-4 col-6-medium col-12-xsmall">
                            <div className="featureimg_cont">
                                <img className="featureimg" src={visualmarketdepth} alt="intro" />
                            </div>
                            <h3>Visual Market Depth</h3>
                            <p>Never miss a change in market direction or the start of a major price move. Receive tailored notifications for EdgeForecaster securities as events happen or as data significantly changes, maximising your trading returns.</p>
                        </section>
                        <section className="col-4 col-6-medium col-12-xsmall">
                            <div className="featureimg_cont">
                                <img className="featureimg" src={documentedaccuracy} alt="intro" />
                            </div>
                            <h3>Documented Accuracy</h3>
                            <p>The better the knowledge, the better the results. Understanding the statistics behind the data is key to increasing your market edge. Through the Edge-Ucation system and EdgeForecaster Knowledge Base, we aim to improve the market comprehension and awareness of all traders and investors. </p>
                        </section>
                        <section className="col-4 col-6-medium col-12-xsmall">
                            <div className="featureimg_cont">
                                <img className="featureimg" src={intravisuals} alt="intro" />
                            </div>
                            <h3>Intra-Period visualisations</h3>
                            <p>One major thing about carving out trading edge is that it doesn’t work if the whole world knows. Markets by nature react if there is too little or too much demand. EdgeForecaster has been developed as a limited community, where only the limited number of users receive the forecast, ensuring demand doesn’t get out of control.</p>
                        </section>
                    </div>
                </div>
                {this.props.format ==="index" ? 
                    <footer className="major">                
                        {this.props.pagewidth ? 
                            featuresbuttonMob
                            :
                            featuresbutton
                        }        
                    </footer>
                :
                <div />
                }
            </div>
            </section>
        </div>
      )
  }
}

export default FeatureDetails
